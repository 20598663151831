<template>
  <div id="Tehis">
    <p :class="[login ? 'withLogin' : 'withoutLogin']">
      <span class="justify-content-center my-1 wrapper-header-img">
        <img v-if="login" class="image-admin"
             :src="`${$options.baseURL}/api/icon/photo/${login}`">
      </span>
      <span class="name-header">{{ name ? name + ' -' : '' }} {{ login ? login + ' -' : '' }} {{
          timeConvert(date)
        }}
      </span>
      <br>
      <template>
        <b>{{ action ? action : '' }}</b> {{ extra && typeof extra !== 'object' ? '- ' + extra : '' }}
      </template>
    </p>
    <ul v-if="typeof extra === 'object'">
      <br>
      <li v-for="(value, key) of $options.extraObj" :key="key">{{ value }} - <b>{{ extra[key] }}</b></li>
    </ul>
  </div>
</template>

<script>
import timeConvertMixin from '../../mixins/timeConvertMixin'
import axios from "axios";

export default {
  name: "TendersHistory",
  mixins: [timeConvertMixin],
  props: {
    login: {
      required: false,
      default: '',
      type: String
    },
    name: {
      required: false,
      default: '',
      type: String
    },
    extra: {
      required: false,
      default: undefined,
      type: [Object, String]
    },
    date: {
      required: false,
      default: undefined,
      type: [String, Number]
    },
    action: {
      required: false,
      default: undefined,
      type: String
    }
  },
  extraObj: {
    'channel_purpose': 'Призначення',
    'connect_type': 'Тип каналу',
    'branch_a': 'Бранч А',
    'branch_a_address': 'Адреса точки А',
    'branch_b': 'Бранч Б',
    'branch_b_address': 'Адреса точки Б',
    'consumer_type': 'Вид каналу',
    'channel_speed': 'Швидкість, Мбіт/с',
    'date_connection': 'Бажана дата підключення',
    'channel_required': 'Вимоги до каналу',
    'chief_it_rp': 'Керівник ІТ РП'
  },
  baseURL: axios.defaults.baseURL,
}
</script>

<style lang="scss" scoped>
#Tehis {
  ul {
    list-style: none;
    padding: 0;
  }

  .name-header {
    color: #757d87;
    font-size: 12px;
  }

  .withLogin {
    padding: 2px 7px;
    border-radius: 4px;
    background-color: #d6ebff;
    border-bottom: 1px solid #c8e4ff;

    span {
      margin-left: 7px;
    }

    .wrapper-header-img {
      width: 36px;
      float: left;
      margin-left: 0;
    }

    .image-admin {
      max-width: 36px;
      max-height: 36px;
      border-radius: 4px;
    }
  }

  .withoutLogin {
    padding: 2px 7px;
    border-radius: 4px;
    background-color: #efefef;
    text-align: right;
    width: 50%;
    margin-left: auto;
    border-bottom: 1px solid #d3d3d3;
  }
}
</style>