import axios from 'axios';

const APIOrderHistory = async (order_history_id) => {
    try {
        return await axios.get(`api/orders/${order_history_id}/history`).then((resp) => resp.data);
    } catch (e) {
        return e.response.data || undefined;
    }
}

export const getOrderHistory = async (order_id) => {
    const response = await APIOrderHistory(order_id);

    if (!response) return;
    response.map((item) => {
        try {
            let obj = JSON.parse(item.extra);
            item.extra = obj;
        } catch (ex) {
            console.log("item not obj")
        }
    });
    return response;
}