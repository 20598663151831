<template>
  <CRow v-if="can('bank_user')" class="w-100 justify-content-center">
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol>
            <h2>Історія по заявці - "{{ $route.params.order_history_id }}"</h2>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <user-info-with-data
            v-for="item in order_history" :key="item.id"
            :action="item.action"
            :name="item.name"
            :login="item.login"
            :date="item.date"
            :extra="item.extra"
        />
      </CCardBody>
      <CCardFooter>
        <CButton
            color="secondary"
            class="px-4 mr-1"
            @click="goBack"
        >
          Назад
        </CButton>
      </CCardFooter>
    </CCard>
  </CRow>
</template>

<script>
import axios from 'axios'
import UserInfoWithData from "../Components/UserInfoWithData.vue";
import {getOrderHistory} from "@/api/getOrderHistory";
import {routerGoToName} from "@/utils/routerUtils";
import {can} from "@/rules/permissions";

export default {
  name: "TendersHistory",
  components: {UserInfoWithData},
  data: () => {
    return {
      order_history: [],
    }
  },
  created() {
    this.baseURL = axios.defaults.baseURL;
    this.getHistory()
  },
  methods: {
    can,
    goBack() {
      routerGoToName('TendersPreview', 'params', {id: this.$route.params.order_history_id});
      // this.$router.push({path: `/tenders/${this.$route.params.order_history_id}`});
    },
    async getHistory() {
      this.order_history = await getOrderHistory(this.$route.params.order_history_id);
    }
  },
}
</script>